#maintenancePage {
    .maintenance-info {
        cursor: pointer;
        &:hover, &.active{
            background-color: $hover-object-info-bg;
        }
    }
}



