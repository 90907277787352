.assign-vehicles {
    .tree-item {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
        background-color: $primary !important;
    }

    .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: $primary !important;
        
    }

    .ant-tree-checkbox-inner:hover, .ant-tree-checkbox .ant-tree-checkbox-checked, .ant-tree-checkbox-checked .ant-tree-checkbox-inner, .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        border: 1px solid $primary;
    }

    .ant-tree-checkbox-checked:after .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner, .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner, .ant-tree-checkbox-inner:hover {
        border-color: $primary !important;
    }
      
    
}