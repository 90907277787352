#ManageAlarmPage {
    .condition-item {
        .card-header-tabs {
            margin: 0
        }
        .card-noShadow {
            border-color: $primary;
            border-width: 0.2px;
            .card-body {
                display: flex;
                flex-direction: row;
                align-items: center;
                .row {
                    flex:1;
                }
            }
        }

    }
}