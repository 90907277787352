.breadcrumb-item {
    > a {
        color: var(--#{$variable-prefix}gray-700);
    }
}

.page-title-right, .page-title-left {
   .breadcrumb{
       margin: 0
   }  
}