.limiter {
    svg {
        color: $limiter-color !important;
    }
    .line {
        flex: 1;
        width: 0.1em;
        height: 100%;
        border: 0.1em solid $limiter-color !important;
        margin: 0 auto;
    }
}