.input-search {
    position: relative;
    input {
        padding-right: 2.4rem;
    }

    .clear-text-icon, .search-text-icon {
        position: absolute;
        right: 0.9rem;
        top: 10px;
        font-size: 18px;
        &.sm {
            right: 0.5rem;
            top: 6px;
            font-size: 16px;
        }
    }

    .clear-text-icon { 
        cursor: pointer;
        opacity: .2;
        &:hover { opacity: .4; }

    }
}

[data-layout-mode="dark"]{
    .input-search {
        .clear-text-icon {
            opacity: .4;
            &:hover { opacity: .8; }
        }
    }
}