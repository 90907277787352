.title-group {
    svg {
      margin-right: 10px;
    }
    font-weight: 500;
    line-height: 26px;
    display: flex;
    align-items: center;
  }
  
  .shift-resume-group {
    margin: 5px 0;
    // .ant-checkbox-inner {
    //   background-color: transparent !important;
    //   border-color: var(--#{$variable-prefix}gray-400) !important;
    // }
    // .ant-checkbox-checked .ant-checkbox-inner:after {
    //   border-color: var(--#{$variable-prefix}primary) !important;
    // }
  }
  
  .transport-group {
    .transport-title-group {
      font-size: 14px;
      font-weight: 600;
    }
    margin-top: 10px;
    .heighlited-name {
      font-size: 14px;
      font-weight: 600;
      color: var(--#{$variable-prefix}primary);
    }
  }