.report-snap {
    .table-responsive {
        th {
            height: 6px;
            line-height: 6px;
        }
        td {
            height: 10px;
            line-height: 10px;
        }
    }

    .card-header {
        padding: 1rem 1rem !important;
    }

    .report-actions {
        display: flex;
        justify-content: space-between;
        padding: 0.3rem 0.5rem !important;
    }

    .report-snap-body {
        display: flex;
        flex-direction: row;
        align-items: end;
        height: calc(100vh - 100px);
    }

}


.report-result-item {
    cursor: pointer;
    button {
        display: none;
    }

    &:hover {
        button {
            display: block;
        }
    }
}


