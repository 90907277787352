.avatar {
    z-index: 3;
    &.avatar-xxs {
        height: 1.5rem;
        width: 1.5rem;
    }
    
    &.avatar-xs {
        height: 2rem;
        width: 2rem;
    }
    
    &.avatar-sm {
        height: 3rem;
        width: 3rem;
    }
    
    &.avatar-md {
        height: 4.5rem;
        width: 4.5rem;
    }
    
    &.avatar-lg {
        height: 6rem;
        width: 6rem;
    }
    
    &.avatar-xl {
        height: 7.5rem;
        width: 7.5rem;
    }
}

.avatar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500; 
}

.avatar-squared {
    border-radius: 90px;
}

.avatar-group {
    display: flex;
    margin-left: 12px;
    .avatar{
        transition: all .2s ease-in-out;
        margin-left: -12px;
        border: 2px solid $card-bg;
        &:hover {
            cursor: pointer;
            z-index: 4;
            transform: translateY(-2px);
        }
    }
}

@mixin avatar-variant($bg) {
    color: $white;
    background-color: $bg;
    border-color: transparent;
}

@each $color,
$value in $theme-colors {
    .avatar-#{$color}{
        @include avatar-variant($value);
    }
}

@mixin avatar-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;
}

@each $color,
$value in $theme-colors {
    .avatar-#{$color}.soft{
        @include avatar-variant-soft($value);
    }
}