.planifier{
    height: 100%;
    flex: 1;
    table{
        width: 100%;
        height: 100%;
        table-layout: fixed;
        th {
          text-align: inherit;
        }
        .fixed-row {
            position: static;
            .hour{
              position: relative;
              top: inherit;
              transform: rotate(-46deg);
            }
          }
        .hour,.day {
            font-size: 8px;
            font-weight: 500;
            cursor: default;
            
        }
        .day { width: 40px }
        .cell {
          cursor: pointer;
          max-width: 20px;
          background-color: var(--#{$variable-prefix}body-bg);
          border-bottom: 1px solid var(--vz-border-color);
          border-right: 1px solid var(--vz-border-color);
          &:hover{
            background-color: var(--vz-border-color);
          }
          -webkit-transition: background-color 500ms linear;
          -ms-transition: background-color 500ms linear;
          transition: background-color 500ms linear;
        }
        .selected {
            background-color: $success;
            // color: #fff;
            border-color: var(--vz-border-color);
            &:hover{
              background-color: $success;
            }
          }
    }

    .details {
        display: flex;
        justify-content: center;
        margin-top: 12px;
    }
    .m-9 {
        margin-left: 9px;
        margin-right: 9px;
    }
    .planning-footer {
        margin-top: 16px;
        float: right;
        button {
            &:last-child {
                margin-left: 8px;
            }
        }
    }
}