.custom-leaflet-container {
    position: fixed;
    height: calc(100vh - #{$header-height});
    top: $header-height;
    width: calc(100%);
}

.custom-leaflet-container-share {
    position: fixed;
    height: calc(100vh);
    top: 0;
    width: calc(100%);
}



[data-sidebar-size="lg"] {
    @media (min-width: 768px){
        .custom-leaflet-container {
            width: calc(100% - #{$vertical-menu-width});
        }   
    }
}

[data-sidebar-size="sm"] {
    @media (min-width: 768px){
        .custom-leaflet-container {
            width: calc(100% - #{$vertical-menu-width-sm});
        }   
    }
}


// =================== MARKERS ===============
.marker-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    // margin: -15px 0 0 -15px;
    svg {
        position: absolute;
        width: 18px;
        font-size: 18px;
        left: 0;
        right: 0;
        margin: 8px auto;
        transform: rotate(45deg);
        text-align: center;
     }
  }
  // to draw white circle
  .marker-pin::after {
      content: '';
      width: 24px;
      height: 24px;
      margin: 3px 0 0 3px;
      background-color: var(--#{$variable-prefix}card-bg);
      // background-color: var(--#{$variable-prefix}input-bg);
      position: absolute;
      border-radius: 50%;
   }
  

   .popup {
    font-size: 13px;
    // font-weight: lighter;
    padding: 5px;
    position: relative;
    width: 263px;
    color: rgba(black, 0.8);
    background-color: rgba(255, 255, 255, 0.816);
    @include boxShadow(5px 5px 10px #ccc);
    .key {
        color: #757575 !important;
    }
    &::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid rgba(255, 255, 255, 0.816);
        bottom: -4px;
        content: '';
        height: 0;
        left: calc(50% - 9px);
        position: absolute;
        width: 0;
    }
    .address-container {
        // @include padding(5, 0, 5, 0);
        .address {
            //height: 30px;
            // @include marginLeft(12);
        }
    }
}

.popup-active {
    color: rgba(white, 1);
    background-color: rgba($primary, 0.97);
    @include boxShadow(0px 1px 10px $primary);
    .key {
        color: rgba(white, 0.7) !important;
    }
    &::after {
        border-top: 5px solid $primary;
    }
}

.popup-hovered {
    color: rgba(white, 1);
    background-color: rgba($success, 0.97);
    @include boxShadow(0px 1px 10px $success);
    .key {
        color: rgba(white, 0.7) !important;
    }
    &::after {
        border-top: 5px solid $success;
    }
}

.leaflet-div-icon {
    border: none !important;
    background: none !important;
}

.tooltip-vehicle{
    margin-left: 17px !important;
    margin-top: -18px !important;
    border: 1px solid #f6f6f6 !important;
    font-size: 10px !important;
    text-align: center !important;
    color: #fff !important;
    padding: 2px 5px !important;
    white-space: nowrap !important;
    background-color: $primary !important;
    border-radius: 0 !important;
    margin-left: 25px !important;
    // &.more-left {
        
    // }
    &:before {
      content: none !important;
    }
  }

// ================================ ACTION CONTROL MAP ================================
.action-map-control, .details-action-map-control {
    z-index: 999;
    position: fixed;
    right: 0;
    padding: calc(1.5rem) calc(1.5rem / 2) 0 calc(1.5rem / 2) !important;
    .btn {
        padding: 0 !important;
        width: 3.1em;
        height: 3.1em;
        background-color: $primary;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
        border-radius: 0.24375em;
        -webkit-border-radius: 0.24375em;
        -moz-border-radius: 0.24375em;
        color: #fff;
        &:hover,
        &:focus,
        &:active {
            background-color: lighten($primary, 8%) ;
        }
        &.active {
            color: $action-map-control-color;
            background-color: $action-map-control-bg ;
        }
    }
}

.details-action-map-control {
    right: 73px;
}


// ant polyine
.hicham, .leaflet-ant-path {
    stroke-opacity: 1; 
}

.leaflet-marker-draggable {
    border: none !important;
    background-color: $light !important;
}

.leaflet-editing-icon{
    border-radius: 12px;
}

.invisible-map {
    display: none !important;
}

.tooltip-marker {
    top: -38px;
    padding: 2px 6px !important;
}

.marker-cluster {
    span {
        line-height: 20px;
    }
}
.marker-cluster-small, .marker-cluster-medium {
    color: $white;
    background-color: transparent !important;
    width: 30px  !important;
    height: 30px  !important;
    div{
        background-color: $info !important;
        font-weight: 700 !important;
        font-size: 11px !important;
        width: 23px !important;
        height: 23px !important;
        span {
            line-height: 23px !important;
        }
    }
}