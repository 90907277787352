#road-page {
    .group-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        
        &:hover, &.active {
            color: $primary;
            background-color: rgba($primary, 0.05);
        }
    }
}

.routes-table { 
    width: "100%";
    td {
        &:first-child {
            width: auto;
        }
        // &:last-child {
        //     width: 100%;
        // }
      }
    thead {
        th {
            // padding: 0 !important;
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        }
        
    }
}