.select-items {
    .accordion-item {
        border: none;
        .accordion-header-container {
            position: relative;
            
            .action-container {
                top: 0;
                bottom: 0;
                position: absolute;
                right: 1em;
                z-index: 99;
                align-items: center;
                display: none;
            }

            &:hover {
                .action-container {
                    display: flex;
                }
            }

        }

        .accordion-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: none;
            .accordion-button {
                padding-top: .8rem;
                padding-bottom: .8rem;
                &.collapsed {
                    .accordion-arrow {
                        transform: rotate(-90deg);
                        transition: all 0.15s ease-in-out;
                    }
                }
                &:focus {
                    outline: 0 !important;
                    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                  }
                &::after {
                    display: none;
                }
            }  
        }
    }
}