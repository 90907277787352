.group {
    .group-header {
        label {
            cursor: pointer;
        }
        .chevron-icon {
            transition: all 0.25s ease-out;
        }
        &.active {
            background: rgba(64, 81, 137, 0.05);
            .chevron-icon {
                transform: rotate(90deg);
            }
        }
        
    }
    .group-body {
        position: absolute;
        left: 0;
        right: 0;
        transition: height 0.25s ease-out;
        overflow: hidden;
        &.active {
            transition: height 0.25s ease-in;
        }
        
    }
    
}


