@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

@import './config/default/bootstrap.scss';
@import "./config/default/theme-light";
@import "./config/default/theme-dark";

@import './structure/topbar.scss';
@import './structure/vertical.scss';
@import './structure/layouts.scss';
@import './structure/page-title-box.scss';

@import './mixins.scss';

// Components
@import './reboot.scss';
@import './components/badge.scss';
@import './components/button.scss';
@import './components/alert.scss';
@import './components/dropdown.scss';
@import './components/avatar.scss';
@import './components/card.scss';
@import './components/breadcrumb.scss';
@import './components/tabs.scss';
@import './components/nav.scss';
@import './components/table.scss';
@import './components/loader.scss';
@import './components/pagination.scss';
@import './components/modal.scss';
@import './components/planning.scss';
@import './components/form-check.scss';
@import './components/progress.scss';
@import './components/input-search.scss';
@import './components/choice-icon.scss';
@import './components/group-managment.scss';

// Components advanced
@import './components/advanced/trajectLimit.scss';
@import './components/advanced/assign-items.scss';
@import './components/advanced/select-items.scss';
@import './components/advanced/display-steps.scss';

// Plugins
@import './plugins/react-select.scss';
@import './plugins/flatPicker.scss';
@import './plugins/leaflet.scss';
@import './plugins/custom-scrollbar.scss';


// Pages
@import './pages/authentification.scss';
@import './pages/user.scss';
@import './pages/vehicleDetails.scss';
@import './pages/assignVehicles.scss';
@import './pages/manageAlarmPage.scss';
@import './pages/objectPage.scss';
@import './pages/maintenancePage.scss';
@import './pages/_driverPage.scss';
@import './pages/_reports.scss';
@import './pages/_routes.scss';

@import './components/helper.scss';
@import '../../../node_modules/antd/dist/antd.min.css';



.ant-popover-inner-content {
    font-weight: 500;
    padding: 5px 10px;
}

.ant-btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
}

.ant-popconfirm {
    z-index: 1060 !important;
}

// Range piker antd
.ant-picker .ant-picker-range {
    background-color: var(--#{$variable-prefix}input-bg) !important;
}


.ant-picker-range {
    .ant-picker-clear{
        right: 14px;
    }

    .ant-picker-active-bar {
        background: $primary;
    }
}

.ant-picker {
    padding: $input-btn-padding-y $input-btn-padding-x;
    background-color:  var(--#{$variable-prefix}input-bg) !important;
    color:  var(--#{$variable-prefix}input-color) !important;
    border: 1px solid var(--#{$variable-prefix}input-border) !important;
    &.ant-picker-focused {
        border: 1px solid $input-focus-border-color !important;  
        box-shadow: unset !important;
    }
    .ant-picker-input {
        input {
            font-size: $font-size-base;
            line-height: $line-height-base;
            color:  var(--#{$variable-prefix}input-color) !important;
            
            &::placeholder {
                color: var(--#{$variable-prefix}body-color) !important;
                background-color:  var(--#{$variable-prefix}input-color) !important;
              }
              
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: var(--#{$variable-prefix}body-color) !important;
                background-color:  var(--#{$variable-prefix}input-color) !important;
              }
              
              &::-ms-input-placeholder { /* Microsoft Edge */
                color: var(--#{$variable-prefix}body-color) !important;
                background-color:  var(--#{$variable-prefix}input-color) !important;
              }
        }
    
    }
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: $primary;
    background: lighten($primary, 55%);
    border-color: lighten($primary, 30%);
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
  background-color: lighten($primary, 50%);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $primary;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: $info;
}
.ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: $info;
}
.ant-steps-item-finish {
    .ant-steps-icon {
        top: -4px !important;
    }
}


.ReactVirtualized__Grid__innerScrollContainer {
    overflow: initial !important;
}
.vertical-center-absolute {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


// Font
.text-whitespace {
    white-space: break-spaces;
}