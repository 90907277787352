.loading-container {
    z-index: 99999999999999;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    left: 0;
    .loading-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-top-width: 0px !important;
        svg {
            animation: spin 2s infinite linear;
            color: $primary;
            font-size: 3em;
        }
        span {
            color: rgba($primary, 0.8);
        }
    }
}
