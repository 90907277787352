@mixin badge-variant-light($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1) !important;
}

@mixin badge-variant-outline($bg) {
    color: $bg;
    border: 1px solid $bg;
    background-color: transparent !important;
}

@each $color, $value in $theme-colors {
    .bg-#{$color}.outline {
        @include badge-variant-outline($value);
    }
}

// badge outline
.badge{
    &.outline {
        background-color: $card-bg;
        border: 1px solid;
    }
}

@each $color, $value in $theme-colors {
    .bg-#{$color}{
        &.light {
            @include badge-variant-light($value);
        }
    }

    .badge {
        &.badge-#{$color}.outline {
            @include badge-variant-outline($value);
        }
    }

    .badge-label {
        &.bg-#{$color} {
            &:before {
                border-right-color: $value;
            }
        }
    }
}


.badge-border {
    border-left: 2px solid;
}

// badge-label

.badge-label {
    margin-left: 8px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        border: 8px solid transparent;
        border-right-color: $primary;
        left: -14px;
        top: 0;
    }
}

// [data-layout-mode="dark"]{
//     .badge{
//         &.bg-dark{
//             background-color: var(--#{$variable-prefix}light) !important;
//         }

//         &.bg-light{
//             background-color: var(--#{$variable-prefix}dark) !important;
//             color: var(--#{$variable-prefix}light) !important;
//         }
//     }
// }