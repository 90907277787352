.object-page {
    position: fixed;
    padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) 0 calc(#{$grid-gutter-width} / 2) !important;

    .list-object, .list-geofence, .list-report {
        max-width: $object-list-width;
        .nav-link {
            padding: 0.5rem 0.2rem 0.5rem 0rem;
        }

        .card-body {
            padding: 0;
            .object-info {
                cursor: pointer;
                img {
                    height: 28px;
                }
                &:hover{
                    background-color: $hover-object-info-bg;
                }
            }
            .object-info-active {
                background-color: $hover-object-info-bg;
            }
        }
       
    } 
    

    .driver-details, .list-report {
        width: $object-details-width;
    }
    .details-object {
        width: $object-details-width;
        .info-detail {
            cursor: default;
            &:hover{
                background-color: $hover-object-info-bg;
            }
        }
    }

    .traject-info {
        cursor: pointer;
        border-bottom: 1px solid var(--vz-gray-300);
        &:hover,&.active {
            background-color: $hover-object-info-bg;
        }
        &:hover{
            .parkingDivIcon {
                svg {
                    color: rgb(25, 118, 210);;
                }
            }
        }
        &.active {
            .parkingDivIcon {
                background-color: rgb(25, 118, 210);
                svg {
                    color: $white;
                }
            }
        }
        .parkingDivIcon {
            background-color: var(--vz-gray-300);
            svg {
                color: var(--vz-gray-700);
            }
        }
        
    }

    .event-info {
        cursor: pointer;
        border-bottom: 1px solid var(--vz-gray-300);
        svg {
            color: var(--vz-gray-500);
        }
        &:hover, &.active{
            background-color: $hover-object-info-bg;
            svg {
                color: $info;
            }
        }
        .address {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: calc(#{$object-details-width} - 4rem); // some width
        }  
    }

    &.share {
        padding: calc(#{$grid-gutter-width}) calc(#{$grid-gutter-width}) 0 calc(#{$grid-gutter-width} / 2) !important;
    }
}

.card-list-page {
    position: fixed;
    padding: calc(#{$header-height}) calc(#{$grid-gutter-width} / 2) 0 0 !important;
    .list-object {
        width: $object-list-width;
        .nav-link {
            padding: 0.5rem 0.2rem 0.5rem 0rem;
        }

        .card-body {
            padding: 0;
            .object-info {
                cursor: pointer;
                img {
                    height: 28px;
                }
                &:hover{
                    background-color: $hover-object-info-bg;
                }
            }
            .object-info-active {
                background-color: $hover-object-info-bg;
            }
        }
       
    }
}

.list-geofence {
    width: $object-list-width !important;
}