.driver-assign-canvas {
    padding: 0;
}
.driver-assign-info {
    cursor: not-allowed;
    &.free-to-use {
        cursor: pointer;
        &:hover {
            border-color: $success !important;
        }

        &.selected {
            background-color: $success;
            border-color: $success !important;
            color: white;
        }
    }
}

#rank-driver-modal {
    &.modal-sm {
        max-width: 400px;
    }
    .resume {
        border-radius: 30px;
        font-weight: 500;
        .ant-rate-star-zero {
            .ant-rate-star-first, .ant-rate-star-second {
                color: $white !important;
            }
        }
    }
}

.list-driver, .details-driver, .list-report {
    width: $driver-list-width !important;
}

[data-layout-mode="light"] {
    #rank-driver-modal {
        .resume {
            background-color: rgba(#E7EAED, 0.9);
        }
    }
}

[data-layout-mode="dark"] {
    #rank-driver-modal {
        .resume {
            background-color: #262a2f;
        }
    }
}