$font-size-mixing: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 36, 48;

@each $font-size-mixing in $font-size-mixing {
  .fs-#{$font-size-mixing} {
    font-size: #{$font-size-mixing}px !important;
  }
}


// Icons Sizes
.icon-xs {
  font-size: 16px;
}

.icon-sm {
  font-size: 18px;
}

.icon-md {
  font-size: 22px;
}

.icon-lg {
  font-size: 24px;
}

.icon-xl {
  font-size: 28px;
}

.icon-xxl {
  font-size: 32;
}

[data-layout-mode="dark"]{
  .btn-close{
      filter: invert(1) grayscale(100%) brightness(200%);
  }
}